import React, { useState } from 'react'
import styled from 'styled-components'
import CustomLink from 'components/CustomLink'
import ImageBackground from 'components/shared/ImageBackground'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

// Images
import orangeArrow from 'img/orange_arrow.svg'

export default function PartnerBox({ title, desc, placeholder, path }) {
  return (
    <>
      <Back src={placeholder} className="video"> 
        <CustomLink to={path} className="w-100">
            <Overlay className="d-flex flex-column">
            <Card className="row position-absolute w-80 m-0 py-2 color-background-main">
                <div className="col-10">
                <p className="m-0 font-size-sm font-weight-xl color-text-light">
                    {title}
                </p>
                <p className="m-0 font-size-sm font-weight-m color-text-light">
                    {desc}
                </p>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                <img alt="" src={orangeArrow} />
                </div>
            </Card>
            </Overlay>
        </CustomLink>
      </Back>
    </>
  )
}

const Back = styled(ImageBackground)`
  width: 100%;
  min-height: 250px;
  display: flex;
`
const Overlay = styled.div`
  width: 100%;
  cursor: pointer;
`
const Card = styled.div`
    bottom: -25px;
    border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;
    border-bottom-left-radius: 11px;
`