/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

// CSS
import 'styles/partnerspage.scss'

// Images
import orangeArrow from 'img/orange_arrow.svg'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import { BannerCustom } from 'components/Elements'
import CustomLink from 'components/CustomLink'
import BrandSlider from 'components/Brands/BrandSlider'
import PartnerBox from 'components/PartnerBox'
import VideoBox from 'components/VideoBox'
import OpenVideo from 'components/OpenVideo'
import NewVideoModal from '../components/VideoModal/NewVideoModal'

const Partners = ({location}) => {
  const {
    partnerPagina: {
      acf: {
        page_content: {
          banner: ervaringBanner,
          second_banner: ervaringBanner2,
          image: {
            localFile: {
              childImageSharp: { fluid: ervaringImage },
            },
          },
        },
      },
      yoast_meta: yoast,
    },
    partners: { edges: partnersenList },
  } = useStaticQuery(graphql`
    {
      partnerPagina: wordpressPage(wordpress_id: { eq: 932 }) {
        acf {
          page_content {
            banner {
              button_text
              text
            }
            second_banner {
              button_text
              text
            }
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
        yoast_meta {
          name
          content
          property
        }
      }
      partners: allWordpressWpPartners(sort: {fields: date, order: DESC}) {
        edges {
          node {
            acf {
              preview {
                title
                image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
            path
            slug
          }
        }
      }
    }
  `)

  const chunk = (array, chunkSize) => {
    const R = []
    for (let i = 0; i < array.length; i += chunkSize) {
      R.push(array.slice(i, i + chunkSize))
    }
    return R
  }

  const [chunkSize, setChunkSize] = useState(6)

  const updateChunk = () => {
    setChunkSize(chunkSize + 6)
  }

  return (
    <Layout>
      <SEO yoast={{ meta: yoast }} />
      <div className="partners">
        <BannerCustom
          to="/showroomafspraak-maken"
          className="color-background-secondary"
          bannerText={ervaringBanner.text}
          bannerButtonText={ervaringBanner.button_text}
        />
        <div className="container py-5">
          <h2 className="m-0 text-center font-size-xl font-weight-xl color-text-main">
            Onze partners
          </h2>
          <p className="m-0 text-center font-size-sm font-weight-xl color-text-contrast">
          Hier vindt u een greep uit onze partners, klik op de foto’s voor meer inspiratie!
          </p>
          <div className="row pt-5">
            {chunk(partnersenList, chunkSize)[0].map((partners, index) => {

              return (
                <Partner
                  key={index}
                  className="col-12 col-lg-4 mb-5"
                  partners={partners.node}
                />
              )
            })}
          </div>
          <div className="d-flex justify-content-center">
            {chunk(partnersenList, chunkSize).length > 1 && (
              <div
                tabIndex="0"
                role="button"
                className="color-background-contrast font-size-xm font-weight-xl color-text-light px-4 py-2 text-uppercase"
                onKeyDown={() => updateChunk()}
                onClick={() => updateChunk()}
              >
                Laad meer
              </div>
            )}
          </div>
        </div>
        <BannerCustom
          to="/contact"
          className="color-background-main"
          bannerText={ervaringBanner2.text}
          bannerButtonText={ervaringBanner2.button_text}
        />
        <BrandSlider title="Onze Merken" className="py-5 my-5" />
      </div>
    </Layout>
  )
}

export default Partners

const Partner = ({ partners, className }) => {

  return (
    <div className={`partners ${className ? `${className}` : ``}`}>
        <div className="partners-image-container">
            <PartnerBox
              title={partners.acf.preview.title}
              placeholder={
                partners.acf.preview.image
              }
              path={partners.path}
            />
        </div>
    </div>
  )
}
